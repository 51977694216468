export const ENV = `${process.env.REACT_APP_ENVIRONMENT_NAME}`;
export const AUTH0_CLIENT_ID = `${process.env.REACT_APP_AUTH0_QS_CLIENT_ID}`;
export const AUTH0_DOMAIN = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
export const AUTH0_AUDIENCE = 'https://api.qs.geo.safeguardglobal.com';
export const USERFLOW_TOKEN = `${process.env.REACT_APP_USERFLOW_TOKEN}`;
export const CONTENTFUL_SPACE_ID = `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`;
export const CONTENTFUL_ACCESS_TOKEN = `${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`;

export const logo_url =
  'https://mldzfkftr9aq.i.optimole.com/vMC8n7g-bMTiBH2T/w:auto/h:auto/q:mauto/https://www.safeguardglobal.com/wp-content/uploads/2019/04/safe-guard-global-white-logo.png';

// console.log('Domain & Client_Id:', AUTH0_DOMAIN, AUTH0_CLIENT_ID);
export const YOUTUBE_URL =
  "https://www.youtube.com/channel/UCMEKxtP84b0ILsuH4OW-XnQ";
export const TWITTER_URL = "https://twitter.com/Safeguard_Globl";
export const LINKEDIN_URL = "https://www.linkedin.com/company/safeguard-globl/";
