import AppLogo from "../assets/logo/logo.webp";
import LogoName from "../assets/logo/logo-name.webp";
import WhiteLogoName from "../assets/logo/logo-name-white.webp";
import Name from "../assets/logo/name.webp";

const logos = {
  icon: AppLogo,
  "icon-name": LogoName,
  "white-name": WhiteLogoName,
  name: Name,
};

const paddings = {
  0: "p-0",
  2: "p-2",
};

export type LogoVariantType = keyof typeof logos;

export type LogoProps = {
  variant?: LogoVariantType;
  height?: number;
  width?: number;
  padding?: keyof typeof paddings;
  className?: string;
};

const Logo = ({
  height = 40,
  width = 56,
  variant = "icon-name",
  padding = 2,
  className,
}: LogoProps) => {
  return (
    <div
      className={`w-fit h-fit ${paddings[padding]} ${
        className ? className : ""
      }`}
    >
      <img
        src={logos[variant] ?? logos.icon}
        height={height}
        width={width}
        alt="Safeguard logo"
      />
    </div>
  );
};

export default Logo;
