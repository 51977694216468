import YouTubeButton from "../assets/icons/youtube-icon.webp";
import TwitterButton from "../assets/icons/twitter-icon.webp";
import LinkedinButton from "../assets/icons/linkedin-icon.webp";
import Logo from "./Logo";
import {
  YOUTUBE_URL,
  TWITTER_URL,
  LINKEDIN_URL,
} from "../constants";

export default function Footer() {
  return (
    <footer className="flex flex-row items-center w-full py-[20px] md:py-[5px] justify-center bg-white">
      <div className="flex flex-col md:flex-row-reverse justify-center items-center gap-[8px]">
        <div className="flex flex-col md:flex-row justify-center items-center gap-[8px] md:gap-[28px] lg:gap-[40px]">
          <a id="Footer-SG-logo" href="/" className="hidden md:flex">
            <div className="w-[50px] md:w-fit">
              <Logo variant="icon" />
            </div>
          </a>

          <div className="flex items-center">
            <p className="hidden md:block text-primary-900 text-[14px] font-normal">
              © COPYRIGHT SAFEGUARD GLOBAL 2024.
            </p>
            <p className="md:hidden text-primary-900 max-[780px]:text-[12px] sm:text-[14px] ml-[10px] font-normal">
              © Safeguard Global 2024.
            </p>
          </div>

          <a
            id="privacy-link"
            href="https://www.safeguardglobal.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-alt max-[780px]:text-[12px] sm:text-[14px] font-normal ml-2 sm:ml-0"
          >
            Privacy Notice
          </a>
        </div>
        <div
          data-testid="footer-socialmedia-icon-container"
          className="flex flex-row md:ml-[28px] lg:ml-[40px] gap-x-8 order-first items-center md:order-last max-[480px]:w-screen max-[480px]:justify-center"
        >
          <a id="twitter-icon" href={TWITTER_URL}>
            <div className="w-fit">
              <img
                src={TwitterButton}
                alt="safeguard twitter page"
                width={25}
                height={25}
              />
            </div>
          </a>
          <a id="youtube-icon" href={YOUTUBE_URL}>
            <div className="w-fit">
              <img
                src={YouTubeButton}
                alt="safeguard youtube page"
                width={35}
                height={25}
              />
            </div>
          </a>
          <a id="linkedin-icon" href={LINKEDIN_URL}>
            <div className="w-fit">
              <img
                src={LinkedinButton}
                alt="safeguard linkedin page"
                width={25}
                height={25}
              />
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
}
